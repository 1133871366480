@media print {
    .no-printme {
      display: none !important;
    }
    .printme {
      display: block !important;
    }
    @page {
      size: 1600px !important;
    }
  
    body,
    html {
      width: 1600px;
    }
  }
  